
import { Component, Vue } from '@/lib/decorator';
import { ref } from 'vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import PaginationBlock from '@/components/Pagination.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import formValidate from '@/lib/formFactory/validate';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getEmployeesList } from '@/api/employees';
import { getAllPartnersList } from '@/api/partners';

import periodsFinanceModule from '@/store/finance/periods';

import UserModule from '@/store/user';
import FinanceModule from '@/store/finance';
import AppTable from '@/components/ui/table/Table.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { debounceDecorator } from '@/lib/util/helpers';
import { convertObjectToQueryParams } from '@/lib/Utils';

@Component({
  components: {
    AppCol,
    AppRow,
    TextDatetime,
    TextMoney,
    AppTable,
    TabsNav,
    PaginationBlock,
    FormAutocomplete,
    FormSelect,
  },
})
export default class FinancePeriodsMain extends Vue {
  form = ref(null);
  validate = formValidate(this.form);
  employee = this.search ?? '';
  items = [];
  partner!: SelectItem;
  partnerItems = [] as SelectItem[];
  searchLoading = false;

  get isSupervisor(): boolean {
    return !!UserModule.securityGroup?.isSupervisor;
  }

  get search(): { id: string; value: string; data?: { fullName: string } | undefined } {
    return periodsFinanceModule.search;
  }

  get tabsNav(): TabsNavInterface {
    return FinanceModule.tabsNav;
  }

  get currentTab(): string {
    return FinanceModule.allTabs.finance_periods.id;
  }

  get periodsTable(): TableApiInterface {
    return periodsFinanceModule.periodsTable;
  }

  @debounceDecorator(600)
  async searchEmployees(employeeName: string): Promise<void> {
    this.searchLoading = true;

    if (employeeName.length < 3) {
      this.items = [];

      return;
    }

    try {
      let filtersObject = { filters: [{ id: 'withPaidEmployee', value: '1' }] };

      if (employeeName) {
        filtersObject.filters.push({ id: 'fullName', value: employeeName });
      }

      const result = await getEmployeesList(1, '', '', `&${convertObjectToQueryParams(filtersObject)}`);

      this.items = result.rows.map((item: { fullName: string; isTest: boolean }) => ({
        ...item,
        fullNameWithTest:
          item.fullName + (item.isTest ? ' <span class="cp-badge cp-badge_test cp-margin__l--3">Test</span>' : ''),
      }));
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors ? error.response.data.errors.fields : error.response.data.message,
        type: 'fail',
      });
    }

    this.searchLoading = false;
  }

  async updateSelectedEmployee(selected: { id: string; value: string; data: { fullName: string } }): Promise<void> {
    selected.value = selected.data.fullName;
    periodsFinanceModule.setSearch(selected);
    await periodsFinanceModule.getPeriodsTable();
  }

  async updatePartner(partner: SelectItem): Promise<void> {
    await periodsFinanceModule.setPartner(partner.id || '');

    if (this.employee) {
      await periodsFinanceModule.getPeriodsTable();
    }
  }

  async getPartnerList(): Promise<void> {
    const result: PartnerSelectListItem[] = await getAllPartnersList();

    this.partnerItems = Object.values(result).map((partner: { uuid: string; legalName: string }) => ({
      id: partner.uuid,
      value: partner.legalName,
    }));
  }

  mounted(): void {
    FinanceModule.initTabsItems();

    if (this.isSupervisor) {
      this.getPartnerList();
    }
  }
}
